import React from "react";
import "./About.css";
import { about } from "../../assets";
import { keypoints } from "../../data";

const About = () => {
  return (
    <section id="about">
      <div className="overlay__div">
        <div className="container">
          <div className="about__image">
          <img src={about} alt="About Prima Consultancy & Constructions - delivering innovative and sustainable construction and consultancy solutions" />
          </div>
          <div className="section__heading">
            <h3 className="title">About Us</h3>
            <h1 className="sub__heading">
              Building Your Vision, Crafting Your Future
            </h1>
            <p className="description">
              Prima Consultancy and Constructions is a forward-thinking company
              dedicated to providing high-quality solutions in structural
              design, retrofitting, structural rehabilitation, architecture,
              landscape design, and surveying. Backed by a team of experienced
              professionals, we are committed to delivering innovative,
              sustainable, and customized solutions that align with the unique
              needs of each project. Our client-focused approach ensures we
              consistently exceed expectations, delivering excellence from
              concept to completion.
            </p>
            <div className="keypoints__container">
              {keypoints.map((keypoint, index) => (
                <div className="flex keypoint" key={index}>
                  <div className="flex__center primary icon">
                    {keypoint.icon}
                  </div>
                  <h4 className="title">{keypoint.title}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
