import React from "react";
import { logo } from "../../assets";
import "./Logo.css";

const Logo = () => {
  return (
    <div className="flex__center logo__container">
      <div className="logo">
      <img src={logo} alt="Prima Consultancy & Constructions logo showcasing expertise in project management, building construction, and consultancy services" />
      </div>
      <h2>
        Prima Consultancy &{" "}
        <span className="text__gradient">Constructions</span>
      </h2>
    </div>
  );
};

export default Logo;
