import Faq from "./components/Faq/Faq";
import About from "./Sections/About/About";
import Blog from "./Sections/Blog/Blog";
import Contact from "./Sections/Contact/Contact";
import Footer from "./Sections/Footer/Footer";
import Hero from "./Sections/Hero/Hero";
import Navbar from "./Sections/Navbar/Navbar";
// import Portfolio from "./Sections/Portfolio/Portfolio";
import Servives from "./Sections/Services/Servives";
import Testimonials from "./Sections/Testimonials/Testimonials";
import WhyChooseUs from "./Sections/WhyChooseUs/WhyChooseUs";
import "swiper/css";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <WhyChooseUs />
      <Servives />
      {/* <Portfolio /> */}
      <Testimonials />
      <Blog />
      <Faq/>
      <Contact />
      <Footer />
    </>
  );
}

export default App;
